export const getLocaleKey = (text: string): string =>
  text ? text.replace(/ /g, '_').replace(/_&_/g, '_').toUpperCase() : '';

export const getDetailKey = (text: string): string =>
  text ? text.replace(/ /g, '').replace(/_/g, '').toLowerCase() : '';

export const compressText = ({
  text,
  length = 100,
  suffix = '...',
}: {
  text: string;
  length?: number;
  suffix?: string;
}) => {
  if (text.length <= length) {
    return text;
  }
  return `${text.slice(0, length)}${suffix}`;
};

export const getPascalCase = (text: string): string => {
  return text
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');
};

export const getJsonParse = (text: string): Record<any, any> | Array<any> | null => {
  try {
    const parsedData = JSON.parse(text);
    return parsedData;
  } catch (e) {
    return null;
  }
};
